import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/invoiceInspection',
    name: 'invoiceInspection',
    component: () => import('@/views/invoiceInspection/index.vue'),
    meta:{
      title:'发票查验'
    }
  },
  {
    path: '/inventoryCollectExport',
    name: 'inventoryCollectExport',
    component: () => import('@/views/inventoryCollectExport/index.vue'),
    meta:{
      title:'药剂出入库汇总'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
